import { useContext, useEffect, useState } from 'react';
import { SectionsContext, useModal } from '../contexts';
import { Workflow, Template } from "../interfaces";
import { PlusIcon, SearchIcon, TrashIcon, ViewGridIcon, ViewListIcon, XIcon } from '@heroicons/react/outline';
import { getData } from '../backend';
import { Link } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import useUser from '../hooks/useUser';
import TextInputField from './TextInputField';
import WorkflowSelectModal from './WorkflowSelectModal';
import WorkflowListCard from './WorkflowListCard';
import WorkflowListRow from './WorkflowListRow';
import TemplateListCard from './TemplateListCard';
import Paginator from './Paginator';
import useWorkflows from '../hooks/useWorkflows';
import useDebounce from '../hooks/useDebounce';
import WorkflowModalDeleteBatch from './WorkflowModalDeleteBatch';

interface Props {
  contentOffset: number,
  createNewWorkflow: (stage: "SOURCE" | "DOCUMENT", blockType: string, file?: any, isPdf?: boolean, options?: Record<string, any>) => void;
}

function WorkflowList(props: Props) {
  const { user, hasPermission } = useUser();
  const { openModal } = useModal();

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const { workflow, updateWorkflowData } = useContext(SectionsContext);

  const [selectedWorkflows, setSelectedWorkflows] = useState<Array<string>>([]);

  const {
    workflowFilter,
    workflowListView,
    workflowSearch,
    workflowShowSearch,
  } = workflow;

  const debouncedSearch = useDebounce(workflowSearch, 200);

  const { data, mutate: mutateWorkflows, isValidating } = useWorkflows({ workflowType: workflowFilter, search: workflowShowSearch ? debouncedSearch : "" }, currentPageNumber);
  const { results: filteredWorkflows, numPages, total } = data ?? { results: undefined, numPages: 1, total: 0 };

  const { data: templates } = useSWRImmutable<Array<Template>>("/templates/", getData);

  useEffect(() => {
    if (workflowShowSearch)
      document.getElementById("search-field")?.focus();
  }, [workflowShowSearch])

  useEffect(() => {
    if (workflowListView !== "ROWS")
      setSelectedWorkflows([]);
  }, [workflowListView]);

  const enabledPrevious = currentPageNumber > 1;
  const enabledNext = currentPageNumber < numPages;

  function onPageChange(newPage: number) {
    setCurrentPageNumber(newPage);
  }

  function onTypeChange(filterType: "ALL" | "PERSONAL" | "TEAM") {
    setCurrentPageNumber(1);
    updateWorkflowData("workflowFilter", filterType);
  }

  const onSearchChange = (newValue: string) => {
    setCurrentPageNumber(1);
    updateWorkflowData('workflowSearch', newValue)
  }

  function handleAddWorkflow() {
    if (!hasPermission(user?.activeTeam, "workflows.create"))
      return;

    openModal(<WorkflowSelectModal title="New Workflow" subtitle="Select a source or template document to start building a workflow" showDocuments showSources onSelection={props.createNewWorkflow} />);
  }

  function handleSelectWorkflow(workflowId: string) {
    if (selectedWorkflows.includes(workflowId))
      setSelectedWorkflows(selectedWorkflows.filter(id => id !== workflowId));
    else
      setSelectedWorkflows([...selectedWorkflows, workflowId]);
  }

  const noFilteredWorkflows = workflowFilter !== "ALL" && !isValidating && (filteredWorkflows ?? []).length === 0;

  if (user === undefined)
    return <img src="https://static.portant.co/portant-loading-gray.svg" className="w-24 h-24 m-auto" alt="Loading Logo" />;

  return (
    <div className="relative flex flex-col bg-white font-gilroy h-full rounded overflow-hidden m-4 px-4 pb-4">
      <div className='flex items-center justify-between px-4 py-2 border-b border-gray-400 flex-shrink-0 h-14'>
        <div className='flex overflow-auto gap-4'>
          <button
            className={`font-semibold font-gilroy ${workflowFilter === "ALL" ? "text-gray-600" : "text-gray-400"} hover:text-gray-600 cursor-pointer select-none whitespace-nowrap`}
            onClick={() => onTypeChange("ALL")}
          >
            All {((total ?? 0) > 0) && `(${total})`}
          </button>
          {user?.activeTeam &&
            <>
              <span className="font-semibold font-gilroy text-gray-400">|</span>
              <button
                className={`font-semibold font-gilroy ${workflowFilter === "PERSONAL" ? "text-gray-600" : "text-gray-400"} hover:text-gray-600 cursor-pointer select-none whitespace-nowrap`}
                onClick={() => onTypeChange("PERSONAL")}
              >
                Personal
              </button>
              <span className="font-semibold font-gilroy text-gray-400">|</span>
              <button
                className={`font-semibold font-gilroy ${workflowFilter === "TEAM" ? "text-gray-600" : "text-gray-400"} hover:text-gray-600 cursor-pointer select-none whitespace-nowrap`}
                onClick={() => onTypeChange("TEAM")}>
                Team ({user.activeTeam?.name})
              </button>
            </>
          }
        </div>
        {(selectedWorkflows.length > 0 && workflowListView === "ROWS") &&
          <div className='flex items-center gap-4 text-sm bg-blue-50 rounded-full h-full px-4 text-gray-600'>
            <div className='flex gap-2 text-sm font-semibold'>
              {selectedWorkflows.length} selected
            </div>
            |
            <button className='flex items-center gap-1 cursor-pointer hover:text-red font-semibold' onClick={() => openModal(<WorkflowModalDeleteBatch workflowIds={selectedWorkflows} onCompletion={() => { mutateWorkflows(); setSelectedWorkflows([]) }} />)}>
              <TrashIcon className='w-4 h-4' />
              Delete
            </button>
            {/* TODO: Add batch sharing */}
            {/* {user?.activeTeam &&
              <button className='flex items-center gap-1 cursor-pointer hover:text-blue font-semibold' onClick={() => openModal(<WorkflowModalShareBatch workflowIds={selectedWorkflows} onCompletion={() => { mutateWorkflows(); setSelectedWorkflows([]) }} />)}>
                <UserGroupIcon className='w-4 h-4' onClick={() => setSelectedWorkflows([])} />
                Share
              </button>
            } */}
            <button className='flex items-center gap-1 cursor-pointer transition-transform hover:scale-125 text-gray-600 font-semibold p-2 -m-2'>
              <XIcon className='w-4 h-4' onClick={() => setSelectedWorkflows([])} />
            </button>
          </div>
        }

        <div className='flex items-center gap-4'>
          <div className='flex items-center'>
            <TextInputField
              id="search-field"
              className={`transition-all ${workflowShowSearch ? "w-full lg:w-80 opacity-100" : "w-10 opacity-0 pointer-events-none"}`}
              value={workflowSearch}
              onChange={onSearchChange}
              placeholder="Search Workflows"
              tabIndex={workflowShowSearch ? 0 : -1}
            />
            <button
              className={`${workflowShowSearch ? "z-10" : "z-0"}`}
              onClick={() => updateWorkflowData('workflowShowSearch', !workflowShowSearch)}
            >
              <SearchIcon className="w-6 h-6 cursor-pointer -ml-8 text-gray-400 hover:text-gray-500" />
            </button>
          </div>
          {(workflowListView === "ROWS") &&
            <button onClick={() => updateWorkflowData("workflowListView", "CARDS")}>
              <ViewGridIcon className="w-6 h-6 ml-2 mr-2 cursor-pointer text-gray-400 hover:text-blue" />
            </button>
          }
          {workflowListView === "CARDS" &&
            <button onClick={() => updateWorkflowData("workflowListView", "ROWS")}>
              <ViewListIcon className="w-6 h-6 ml-2 mr-2 cursor-pointer text-gray-400 hover:text-blue" />
            </button>
          }

          <button className='btn btn-blue text-sm py-1 px-4' onClick={handleAddWorkflow} disabled={!hasPermission(user?.activeTeam, "workflows.create")}>
            <PlusIcon className='w-4 h-4 mr-2' /> Add Workflow
          </button>
        </div>
      </div>

      {(noFilteredWorkflows && total !== 0 && debouncedSearch === "") && (
        <div className='flex flex-col items-center gap-2 my-12'>
          <h4 className="font-gilroy font-semibold text-gray-600">No workflows of the chosen category</h4>
          <h6 className="font-gilroy font-semibold text-sm mb-4 text-gray-400">Click the "All  Workflows" button to clear the filter</h6>
        </div>
      )}

      {(templates && total === 0 && debouncedSearch === "" && !isValidating) &&
        <div className='flex flex-col items-center mb-4 w-full overflow-auto'>
          <div className="flex flex-col gap-2 justify-center items-center w-full mt-10 mb-14">
            <div className="font-gilroy font-semibold text-lg">Automate a document workflow</div>
            <button id="workflow-get-started-button" className="btn btn-blue" onClick={handleAddWorkflow}>
              Start From Scratch
            </button>
          </div>

          <div className='font-semibold font-gilroy text-sm text-gray-400'>or start from one of our workflow templates... <Link to='/templates' className='underline hover:text-blue'>see more</Link> </div>
          <div className="grid grid-cols-3 lg:grid-cols-4 grid-rows-none gap-8 row bg-white p-4 overflow-y-auto font-gilroy w-full" style={{ maxHeight: `calc(100vh - 160px - ${props.contentOffset}px)` }}>
            {templates.slice(0, ((filteredWorkflows ?? []).length === 0) ? 8 : 4).map((template: Template) =>
              <TemplateListCard key={template.id} template={template} />
            )}
          </div>
        </div>
      }

      {(isValidating && !filteredWorkflows) && <img src="https://static.portant.co/portant-loading-gray.svg" className="w-24 h-24 m-auto" alt="Loading Logo" />}

      {filteredWorkflows && workflowListView === "ROWS" &&
        <div className="flex flex-col px-8 -mx-4 py-0 overflow-y-auto" style={{ height: `calc(100vh - 144px - ${props.contentOffset}px)` }}>
          {filteredWorkflows.map((workflow: Workflow) =>
            <WorkflowListRow key={workflow.id} workflow={workflow} mutateWorkflowList={mutateWorkflows} onSelect={handleSelectWorkflow} selected={selectedWorkflows.includes(workflow.id)} disableOptions={selectedWorkflows.length > 0} />
          )}
          {(filteredWorkflows.length === 0 && debouncedSearch !== "") &&
            <div className="mt-20 mx-auto font-gilroy text-gray-600">
              {!!debouncedSearch ? `No results found for "${debouncedSearch}"` : "No results"}
            </div>
          }
          {(templates && filteredWorkflows.length > 0 && filteredWorkflows.length <= 4) &&
            <div className='flex flex-col items-center mt-8 -mx-4'>
              <div className='font-semibold font-gilroy text-sm text-gray-400'>Automate another process from one of our templates... <Link to='/templates' className='underline hover:text-blue'>see more</Link> </div>
              <div className="grid grid-cols-3 lg:grid-cols-4 grid-rows-none gap-8 row bg-white p-4 overflow-y-auto font-gilroy w-full" style={{ maxHeight: `calc(100vh - 160px - ${props.contentOffset}px)` }}>
                {templates.slice(0, (filteredWorkflows.length === 0) ? 8 : 4).map((template: Template) =>
                  <TemplateListCard key={template.id} template={template} />
                )}
              </div>
            </div>
          }
        </div>
      }

      {filteredWorkflows && workflowListView === "CARDS" &&
        <div className="grid grid-cols-3 lg:grid-cols-4 grid-rows-none gap-8 row bg-white p-4 overflow-y-auto font-gilroy w-full" style={{ maxHeight: `calc(100vh - 144px - ${props.contentOffset}px)` }}>
          {filteredWorkflows.map((workflow: Workflow) =>
            <WorkflowListCard key={workflow.id} workflow={workflow} mutateWorkflowList={mutateWorkflows} />
          )}
          {(filteredWorkflows.length === 0 && debouncedSearch !== "") &&
            <div className="mt-20 mx-auto font-gilroy text-gray-600 col-span-4">
              {!!debouncedSearch ? `No results found for "${debouncedSearch}"` : "No results"}
            </div>
          }
          {(templates && filteredWorkflows.length > 0 && filteredWorkflows.length <= 4) &&
            <div className='flex flex-col items-center mt-8 -mx-4 col-span-4'>
              <div className='font-semibold font-gilroy text-sm text-gray-400'>Automate another process from one of our templates... <Link to='/templates' className='underline hover:text-blue'>see more</Link> </div>
              <div className="grid grid-cols-3 lg:grid-cols-4 grid-rows-none gap-8 row bg-white p-4 overflow-y-auto font-gilroy w-full" style={{ maxHeight: `calc(100vh - 160px - ${props.contentOffset}px)` }}>
                {templates.slice(0, (filteredWorkflows.length === 0) ? 8 : 4).map((template: Template) =>
                  <TemplateListCard key={template.id} template={template} />
                )}
              </div>
            </div>
          }
        </div>
      }

      <Paginator
        enabledNext={enabledNext}
        enabledPrevious={enabledPrevious}
        onPageChange={onPageChange}
        pageNumber={currentPageNumber}
        numPages={numPages}
        className="mt-auto"
      />
    </div>
  );
}

export default WorkflowList;
