import { useEffect, useState } from "react";
import { OAuthError, postData } from "../backend";
import { useModal } from "../contexts";
import useScript from "../hooks/useScript";
import { BLOCK_ICON_MAP, BLOCK_NAME_MAP, GOOGLE_MIMETYPE_MAP, GOOGLE_VIEW_ID_MAP } from "../utils";
import ErrorModal from "./ErrorModal";
import OAuthModal from "./OAuthModal";
import { BasicWorkflow } from "../interfaces";
import LoadingModal from "./LoadingModal";

interface Props {
  blockType: string;
  title: string;
  subtitle: string;
  openBlock: string | null;
  setOpenBlock: (s: string) => void;
  onSelection: (blockType: string, file?: any) => void;
}

const scopes = ["https://www.googleapis.com/auth/drive"];

function WorkflowSelectGoogleButton(props: Props) {
  const { openModal } = useModal();

  const status = useScript('https://apis.google.com/js/api.js');
  const [pickerLoaded, setPickerLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (status !== "ready")
      return;

    // @ts-ignore
    const gapi = window.gapi;

    gapi.load('picker',
      () => setPickerLoaded(true)
    );
  }, [status]);

  async function handleOpenPicker() {
    function handlePickerSelection(pickerResult: any) {
      if (pickerResult.action === 'picked')
        props.onSelection(props.blockType, pickerResult.docs[0])
    }

    try {
      const accessToken = await postData("/auth/access-token/", { scopes });

      // @ts-ignore
      const google = window.google;

      const picker = new google.picker.PickerBuilder()
        .setAppId(process.env.REACT_APP_GOOGLE_APP_ID)
        .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
        .setOAuthToken(accessToken)
        .setSize(document.body.clientWidth, document.body.clientHeight)
        .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
        .setCallback(handlePickerSelection)

      const docsView = new google.picker.DocsView(google.picker.ViewId[GOOGLE_VIEW_ID_MAP[props.blockType]])
      docsView.setMode(google.picker.DocsViewMode.LIST)
      picker.addView(docsView);

      const rootView = new google.picker.DocsView()
      rootView.setMode(google.picker.DocsViewMode.LIST)
      rootView.setMimeTypes(GOOGLE_MIMETYPE_MAP[props.blockType])
      rootView.setIncludeFolders(true)
      rootView.setParent("root")
      picker.addView(rootView);

      const sharedView = new google.picker.DocsView()
      sharedView.setMode(google.picker.DocsViewMode.LIST)
      sharedView.setMimeTypes(GOOGLE_MIMETYPE_MAP[props.blockType])
      sharedView.setIncludeFolders(true)
      sharedView.setEnableDrives(true)
      picker.addView(sharedView);

      picker.build()
        .setVisible(true);

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} onClose={handleOpenPicker} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  async function handleCreateNewFile(newWorkflow?: BasicWorkflow) {
    try {
      openModal(<LoadingModal title="Authenticating" subtitle="Validating if we have the necessary permissions" />);
      await postData("/auth/access-token/", { scopes });
      props.onSelection(props.blockType);

    } catch (error) {
      if (error instanceof OAuthError)
        openModal(<OAuthModal oauthUrl={error.oauthUrl} onClose={handleCreateNewFile} />);
      else
        openModal(<ErrorModal details={error} />);
    }
  }

  if (props.openBlock === props.blockType)
    return (
      <div className="flex flex-shrink-0 items-center border-2 border-gray-200 rounded">
        <button
          className="group flex items-center w-full border-2 border-[#00000000] hover:border-blue rounded cursor-pointer h-full px-8 py-4 -m-0.5"
          onClick={handleOpenPicker}
          disabled={!pickerLoaded}
        >
          <img className="w-12 h-12" src={BLOCK_ICON_MAP[props.blockType]} alt={BLOCK_NAME_MAP[props.blockType]} />
          <div className="text-sm group-disabled:text-gray-400 group-disabled:cursor-progress font-gilroy font-semibold text-gray-600 ml-4">
            Select {props.title} file
          </div>
        </button>
        <button
          className="flex items-center w-full border-2 border-[#00000000] hover:border-blue rounded cursor-pointer h-full px-8 py-4 -m-0.5"
          onClick={() => handleCreateNewFile()}
        >
          <img className="w-12 h-12" src="https://static.portant.co/drive-new-icon.svg" alt="New File Icon" />
          <div className="text-sm font-gilroy font-semibold text-gray-600 ml-4">
            New {props.title} file
          </div>
        </button>
      </div>
    );

  return (
    <button className="flex flex-shrink-0 items-center border-2 border-gray-200 hover:border-blue rounded cursor-pointer px-8 py-4" onClick={() => props.setOpenBlock(props.blockType)}>
      <img className="w-12 h-12" src={BLOCK_ICON_MAP[props.blockType]} alt={BLOCK_NAME_MAP[props.blockType]} />
      <div className="flex flex-col items-start ml-4">
        <div className="font-gilroy font-semibold text-lg text-gray-600">
          {props.title}
        </div>
        <div className="font-gilroy font-semibold text-sm text-gray-400">
          {props.subtitle}
        </div>
      </div>
    </button>
  );
}

export default WorkflowSelectGoogleButton;
