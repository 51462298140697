import { DocumentTextIcon, ExclamationIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import useUser from "../hooks/useUser";

interface Props extends React.HTMLProps<HTMLDivElement> {
  exceededAmount?: number;
  disableLinkToBilling?: boolean;
}

function UsagePanel(props: Props) {
  const { exceededAmount, disableLinkToBilling, ...htmlProps } = props;

  const history = useHistory();
  const { user } = useUser();

  if (!user)
    return null;

  if (!user.subscription) {
    return (
      <div {...htmlProps} onClick={() => history.push("/settings/billing")}>
        <div className="group flex items-center bg-gray-100 border-2 border-yellow text-yellow text-xs font-bold rounded p-2 gap-2 w-full cursor-pointer">
          <ExclamationIcon className="w-5 h-5 flex-shrink-0" />
          <div className="font-bold font-gilroy text-black w-full">
            Your trial of Portant has expired
          </div>
          <button className="group-hover:underline text-blue font-gilroy font-bold flex-shrink-0 text-xs">
            Upgrade
          </button>
        </div>
      </div>
    );
  }

  const docsLimit = user.subscription?.featureDocumentLimit || 100;
  const usage = user.usage; // TODO: Reflect teams usage

  const usagePercentage = usage / docsLimit;

  let usageColour = "bg-blue";
  if (usagePercentage >= 0.8) usageColour = "bg-yellow";
  if (usagePercentage >= 1.0) usageColour = "bg-red";

  const date = moment(user.subscription?.dateUsagePeriodTo).format("DD MMM");

  return (
    <div {...htmlProps} onClick={() => !disableLinkToBilling && history.push("/settings/billing")}>
      <div className={`group flex items-center bg-gray-100 text-gray-600 text-xs rounded p-2 gap-2 w-full ${disableLinkToBilling ? "cursor-default" : "cursor-pointer"}`}>
        <DocumentTextIcon className="w-5 h-5 flex-shrink-0" />
        <div className="flex flex-col w-full">
          <div className="flex justify-between">
            <div className="font-gilroy font-semibold">
              Usage <span className="font-normal">(resets {date})</span>
            </div>
            <div>
              <span className={`${exceededAmount || (usage > docsLimit) ? "text-red font-semibold" : "text-gray-600"}`}>{props.exceededAmount ?? usage}</span>/{docsLimit}
            </div>
          </div>
          <div className={`relative h-1 w-full rounded-full overflow-clip ${!exceededAmount ? "bg-gray-300" : "bg-red"}`}>
            <div className={`absolute h-full rounded-full transition-width ${usageColour}`} style={{ width: (usagePercentage * 100) + "%" }} />
          </div>
        </div>
        {!disableLinkToBilling &&
          <button className="group-hover:underline text-blue font-gilroy font-bold flex-shrink-0">
            Manage
          </button>
        }
      </div>
    </div>
  );
}

export default UsagePanel;
