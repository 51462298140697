import { useModal } from "../contexts";
import { BLOCK_ICON_MAP, BLOCK_NAME_MAP, HUBSPOT_PRIMARY_OBJECT_OPTIONS } from "../utils";
import ProLabel from "./ProLabel";
import PayWallModalPro from "./PayWallModalPro";
import BetaLabel from "./BetaLabel";
import { HubspotPrimaryObject } from "../interfaces";
import { useState } from "react";
import SmallSelectField from "./SmallSelectField";

interface BaseProps {
  title: string;
  subtitle: string;
  onSelection: (blockType: string, primaryObject: HubspotPrimaryObject) => void;
  beta?: boolean;
}

interface NonProProps extends BaseProps {
  pro?: false;
}

interface ProProps extends BaseProps {
  pro: true;
  hasProFeature: boolean;
}


type Props = NonProProps | ProProps;

function WorkflowSelectHubspotButton(props: Props) {
  const { openModal } = useModal();
  const [openBlock, setOpenBlock] = useState(false);

  function createBlock(selectedObject: HubspotPrimaryObject) {
    if (!selectedObject)
      return;

    if (props.pro && !props.hasProFeature)
      openModal(<PayWallModalPro />);
    else
      props.onSelection("HUBSPOT", selectedObject);
  }

  return (
    <div tabIndex={0} className={`flex flex-shrink-0 items-center border-2 border-gray-200 rounded cursor-pointer px-8 ${openBlock ? "" : "hover:border-blue"}`} onClick={() => setOpenBlock(true)}>
      <img className="w-12 h-12 my-4" src={BLOCK_ICON_MAP["HUBSPOT"]} alt={BLOCK_NAME_MAP["HUBSPOT"]} />
      <div className="flex flex-col items-start ml-4">
        <div className="font-gilroy font-semibold text-lg text-gray-600">
          {props.title}
        </div>
        <div className="font-gilroy font-semibold text-sm text-gray-400">
          {openBlock ? "Please select the primary object of this workflow" : props.subtitle}
        </div>
      </div>
      {openBlock &&
        <SmallSelectField
          className="ml-auto w-48"
          value={""}
          onChange={(value: string) => createBlock(value as HubspotPrimaryObject)}
        >
          <option disabled value="">Select primary object</option>
          {HUBSPOT_PRIMARY_OBJECT_OPTIONS.map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
        </SmallSelectField>
      }
      {props.pro && <ProLabel />}
      {props.beta && <BetaLabel />}
    </div>
  );
}

export default WorkflowSelectHubspotButton;
