import { useCallback, useEffect, useMemo, useState } from "react";
import { Output, WorkflowAutomation } from "../interfaces";
import BreadcrumbStepper from "./BreadcrumbStepper";
import AppHubspotAutomationSkeleton from "./AppHubspotAutomationSkeleton";
import { ChevronLeftIcon, ChevronRightIcon, CogIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { getData } from "../backend";
import { MIMETYPE_ICON_MAP } from "../utils";

interface Props {
  hasDocuments: boolean;
  automation?: WorkflowAutomation;
  payload: string;
  stepMap: Record<string, string>;
}

function AppHubspotAutomation(props: Props) {
  const { hasDocuments, automation, payload, stepMap } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
  const [error, setError] = useState(false);
  const [items, setItems] = useState<Array<Output>>([]);

  const currentStep = useMemo(() => {
    if (items?.[currentDocumentIndex]?.file?.url)
      return Object.keys(stepMap).indexOf("AUTOMATION") + 1
    else
      return Object.keys(stepMap).indexOf("AUTOMATION")
  }, [items, currentDocumentIndex, stepMap]);

  const handleChangeDocument = (direction: -1 | 1) => {
    const newIndex = Math.min(Math.max(0, currentDocumentIndex + direction), items?.length - 1);

    if (newIndex !== currentDocumentIndex && items[newIndex]) {
      setCurrentDocumentIndex(newIndex);
    }
  }

  const pollAutomation = useCallback(async (offset = 0) => {
    if (!automation?.id) return;

    try {
      const data = await getData(`/hubspot/app-automations/${payload}/${automation.id}/`);
      if (data?.items?.[0]?.status === "PENDING")
        setTimeout(() => pollAutomation(offset + 500), 1000 + offset);
      if (data?.items?.[0]?.status === "ERROR")
        setError(true);
      else if (data?.items?.[0])
        setItems(data.items[0].outputs);
      if (data?.items?.[0]?.status !== "PENDING")
        setIsLoading(false)
    }
    catch {
      setError(true);
      setIsLoading(false);
    }
  }, [payload, automation?.id]);

  useEffect(() => {
    pollAutomation();
  }, [pollAutomation]);

  console.log(error);

  if (error)
    return (
      <div className="w-screen h-screen flex flex-col gap-2 items-center justify-center">
        <h3 className="font-gilroy font-semibold text-2xl text-red"> Something went wrong! </h3>
        <p>An error occurred while trying to display the outputs of the automation. </p>
        <a
          href={items?.[0]?.workflow ? `https://app.portant.co/w/${items?.[0]?.workflow}/outputs` : 'https://app.portant.co'}
          target="_blank"
          className="text-blue-10 underline"
          rel="noreferrer"
        >
          Please check the status of the automation on Portant.
        </a>
      </div>
    )

  const file = items?.[currentDocumentIndex]?.file || items?.[currentDocumentIndex]?.filePdf;

  return (
    <div className="h-screen w-screen flex flex-col items-center">
      <BreadcrumbStepper currentStep={currentStep} steps={Object.values(stepMap)} className="mx-auto" />
      {!hasDocuments ? (
        <div className="h-full w-full flex flex-col gap-4 items-center justify-center">
          <CogIcon className="h-16 w-16 text-blue animate-spin" />
          <h2 className="text-gray-800 font-semibold text-center text-sm max-w-md">This automation has been started and will be completed shortly.<br />This window can now be closed.</h2>
        </div>
      ) : (
        <div className="flex flex-col items-start h-full w-full max-w-[860px] bg-[#f9fbfd] rounded-xl overflow-hidden drop-shadow-lg mt-6">
          <div className="bg-white w-full flex justify-between px-4 py-2">
            <div className="flex items-center gap-1 font-bold text-sm">
              {(isLoading && !file)
                ? (
                  <div className="flex items-center gap-5">
                    <div className="h-5 w-5 bg-gray-200 animate-pulse" />
                    <div className="w-24 h-4 bg-gray-200 animate-pulse" />
                  </div>
                ) : (
                  <a className="flex items-center gap-2 text-sm font-semibold font-gilroy cursor-pointer hover:text-blue hover:underline" href={file?.url} target="_blank" rel="noreferrer">
                    <img className="h-5 w-5 mr-2 rounded-md" src={MIMETYPE_ICON_MAP[file?.mimeType!]} alt="Google File Icon" />
                    <h3 className="truncate max-w-[400px]"> {file?.name}</h3>
                    {file?.url && <ExternalLinkIcon className="h-4 w-4 group-hover:text-blue" />}
                  </a>
                )}
            </div>
            <div className="flex items-center gap-4">
              {items?.length > 1 &&
                <div className="flex items-center ml-auto">
                  <ChevronLeftIcon className="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-pointer" onClick={() => handleChangeDocument(-1)} />
                  <div className="font-semibold text-gray-600 font-gilroy w-12 text-center">
                    {currentDocumentIndex + 1} / {items.length}
                  </div>
                  <ChevronRightIcon className="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-pointer" onClick={() => handleChangeDocument(1)} />
                </div>
              }
              {(file?.url) && (
                <button
                  className="btn btn-white text-sm h-8"
                  onClick={() => window.open(file?.url, "_blank")}
                >
                  Open
                </button>
              )}
            </div>
          </div>
          {(isLoading && !file) && <AppHubspotAutomationSkeleton />}
          {(file?.url) &&
            <iframe title="Google Editor" className="w-full h-full border border-gray-300 rounded overflow-hidden" src={`${file?.url?.replace("/edit", "/preview").replace("/view", "/preview")}?widget&rm=minimal`} />
          }
        </div>
      )
      }
    </div>
  );
}

export default AppHubspotAutomation;
