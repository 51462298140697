import { ChevronDownIcon } from '@heroicons/react/outline';
import { useId } from 'react';

interface Props {
  label?: string;
  className?: string;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  value: string;
  onChange: (value: string) => void;
  onClick?: () => void;
  disabled?: boolean;
  invalid?: boolean;
}

function SmallSelectField(props: Props) {

  const id = useId();

  return (
    <div className={'flex flex-col ' + props.className}>
      <div className="relative w-full text-sm">
        <select
          id={id}
          className={`border-2 rounded-md py-1 pl-2 pr-5 w-full font-gilroy truncate ${props.disabled ? "text-gray-400 cursor-not-allowed" : !props.value ? "text-gray-400" : "text-black"} ${props.invalid ? "border-yellow" : "border-gray-400"} ${props.label ? 'mt-2' : ''}`}
          value={props.value}
          onChange={(e) => props.onChange && props.onChange(e.target.value)}
          onClick={props.onClick}
          disabled={props.disabled}
          style={{
            // Remove default arrow for select field
            MozAppearance: 'none',
            WebkitAppearance: 'none',
          }}>
          {props.children}
        </select>
        {props.label && <label htmlFor={id} className="text-xs font-semibold text-gray-400 bg-white left-2 top-0 absolute px-1 w-auto">
          {props.label}
        </label>}
        <ChevronDownIcon className={`w-4 h-4 absolute right-2 pointer-events-none text-gray-400 ${props.label ? 'top-4' : 'top-2'}`} />
      </div>
    </div>
  );
};

export default SmallSelectField;
