import { CalendarIcon, DocumentTextIcon, ExclamationIcon, UserGroupIcon } from '@heroicons/react/outline';
import { BLOCK_ICON_MAP, BLOCK_NAME_MAP, capitalize } from "../utils";
import HeroIcon from "./HeroIcon";
import moment from 'moment';
import { Workflow } from "../interfaces";
import WorkflowListItemOptions from "./WorkflowListItemOptions";
import useUser from "../hooks/useUser";
import { KeyedMutator } from "swr";
import { Link } from 'react-router-dom';

interface Props {
  workflow: Workflow;
  mutateWorkflowList: KeyedMutator<any>;
  onSelect: (workflowId: string) => void;
  selected: boolean;
  disableOptions?: boolean;
}

function WorkflowListRow(props: Props) {
  const { workflow, mutateWorkflowList, selected, onSelect } = props;

  const { user } = useUser();

  return (
    <div className="flex items-center justify-between text-sm border-b last:border-0 border-gray-200">
      <input type="checkbox" checked={selected} className='h-4 w-4 cursor-pointer hover:border-gray-300 my-auto mr-4' onClick={(e) => { onSelect(workflow.id); e.stopPropagation() }} />

      <Link to={`/w/${workflow.id}`} className="flex items-center h-full py-4 w-1/2 pr-10 group">
        <div className="flex justify-center items-center w-10 h-10 mr-4 rounded bg-blue select-none" style={{ backgroundColor: workflow.color }}>
          <HeroIcon icon={workflow.icon || "DocumentText"} />
        </div>
        <div className="flex items-center group cursor-pointer rounded group-hover:bg-blue-50 h-full -mx-2 px-2">
          <div className="font-semibold text-gray-600 group-hover:text-blue overflow-ellipsis overflow-hidden whitespace-nowrap">
            {workflow.name}
          </div>
          {(workflow.team) &&
            <div className="flex items-center gap-2 text-gray-400 font-semibold text-sm relative">
              <UserGroupIcon className="ml-2 h-4 w-4" />
              {workflow.owner?.id === user?.id ? "Me" : workflow.owner?.fullName} <span className="text-xs -ml-1">(Owner)</span>
            </div>
          }
        </div>
      </Link>

      <div className="flex items-center justify-between w-1/2">

        <div className="flex items-center justify-start gap-2 text-sm font-gilroy text-gray-600 flex-shrink-0">
          {workflow.previewSourceType
            ? <>
              <div className="group relative flex items-center gap-1">
                <img className="w-4 h-4" src={BLOCK_ICON_MAP[workflow.previewSourceType]} alt="Source icon" />
                <div className="hidden group-hover:flex absolute bg-black text-white top-4 left-4 px-2 whitespace-nowrap rounded-sm rounded-tl-none shadow">
                  Source: {BLOCK_NAME_MAP[workflow.previewSourceType]}
                </div>
              </div>

              <div className="w-px h-4 bg-gray-400" />

              <div className="group relative flex items-center gap-1">
                <DocumentTextIcon className="w-4 h-4" />
                {workflow.itemsTotal}
                <div className="hidden group-hover:flex absolute bg-black text-white top-4 left-4 px-2 whitespace-nowrap rounded-sm rounded-tl-none shadow">
                  Total usage
                </div>
              </div>

              <div className="group relative flex items-center gap-1">
                <CalendarIcon className="w-4 h-4" />
                {workflow.itemsPeriod}
                <div className="hidden group-hover:flex absolute bg-black text-white top-4 left-4 px-2 whitespace-nowrap rounded-sm rounded-tl-none shadow">
                  Usage this period
                </div>
              </div>
            </>
            : <>
              <div className="group relative flex items-center gap-1 text-yellow font-semibold">
                <ExclamationIcon className="w-4 h-4" />
                Add source
              </div>
            </>
          }
        </div>

        <div className="flex items-center justify-start font-gilroy font-semibold text-sm text-gray-600 flex-shrink-0">
          {capitalize(moment(workflow.updatedAt).fromNow())}&nbsp;
          {workflow.team && workflow.lastEditedBy &&
            <span className="text-gray-400">by {workflow.owner?.id === user?.id ? "me" : workflow.owner?.fullName}</span>
          }
        </div>

        <div className="flex-shrink-0" onClick={e => e.stopPropagation()}>
          <WorkflowListItemOptions workflow={workflow} mutateWorkflowList={mutateWorkflowList} disabled={props.disableOptions} />
        </div>
      </div>
    </div>
  );
}

export default WorkflowListRow;
