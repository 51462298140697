type Props = {
  reason?: 'INCOMPLETE_WF' | 'DEFAULT' | 'UNKNOWN_ERROR';
}

function AppHubspotError({ reason = 'DEFAULT' }: Props) {
  const title = {
    INCOMPLETE_WF: "Incomplete workflow",
    DEFAULT: "Trouble connecting",
    UNKNOWN_ERROR: "Something went wrong",
  }
  const subtitle = {
    INCOMPLETE_WF: "Please complete the workflow in Portant",
    DEFAULT: "Please check your connection",
    UNKNOWN_ERROR: "An error has occured when starting this automation",
  }

  const detail = {
    INCOMPLETE_WF: <>
      The workflow you are trying to run is incomplete. Please complete the workflow in
      <a className="text-blue underline" target="_blank" rel="noreferrer" href="https://app.portant.co"> Portant </a>
      before starting the automation from HubSpot.
    </>,
    DEFAULT: <>
      Your Portant account might not be connected, or might be connected to a different Hubpost account. Please visit the
      <a className="text-blue underline" target="_blank" rel="noreferrer" href="https://app.portant.co/settings/permissions"> Permissions Settings </a>
      in Portant and ensure you are connected to the correct HubSpot account.
    </>,
    UNKNOWN_ERROR: ""
  }

  return (
    <div className="flex flex-col w-screen h-screen justify-center items-center">
      <div className="flex flex-col w-[600px] mx-auto">
        <h3 className="font-gilroy font-semibold text-xl text-red">{title[reason]}</h3>
        <p className="mb-8 text-gray-600">{subtitle[reason]}</p>
        <div className="w-full mx-auto text-gray-600">
          {detail[reason]}
        </div>
        <div className="w-full mx-auto text-gray-600">
          Please check your integration settings in Portant to ensure you are connected to all required services such as HubSpot and Google Drive.
        </div>
        <a className="mt-2 font-semibold text-blue hover:underline" href="https://app.portant.co/settings/permissions" target="_blank" rel="noreferrer">Open Portant</a>
      </div>
    </div>
  );
}

export default AppHubspotError;
