import { deleteData } from '../backend';
import { useModal } from '../contexts';
import { useState } from 'react';
import Modal from './Modal';

interface Props {
  workflowIds: Array<string>;
  onCompletion: () => void;
}

function WorkflowModalDeleteBatch(props: Props) {
  const { workflowIds } = props;
  const { closeModal } = useModal();

  const [progress, setProgress] = useState<number>(0);

  async function handleConfirm() {
    setProgress(1);

    for (const workflowId of workflowIds) {
      await deleteData(`/workflows/${workflowId}/`)
        .catch((error) => { console.log(error) });

      setProgress((progress) => progress + 1);
    }

    closeModal(props.onCompletion);
  }

  return (
    <Modal
      title={
        <h3 className="font-gilroy font-bold text-red text-xl">
          Delete {props.workflowIds.length} Workflows
        </h3>
      }
      subtitle={`Are you sure you would like the delete ${props.workflowIds.length > 1 ? 'these' : 'this'} workflow${props.workflowIds.length > 1 ? 's' : ''}? This action cannot be undone.`}
      secondaryButtonProps={{ text: "Cancel", onClick: closeModal }}
      primaryButtonProps={{ className: "btn-red", text: "Confirm", onClick: () => handleConfirm(), disabled: progress > 0 }}
      size='sm'>
      <div className="font-semibold text-gray-400 text-sm">
        {progress >= 1
          ? <>Deleting {progress} of {workflowIds.length} workflows...</>
          : <>&nbsp;</>
        }
      </div>
    </Modal>
  );
}

export default WorkflowModalDeleteBatch;
