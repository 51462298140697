import jwt_decode from "jwt-decode";

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const titleCase = (str: string) =>
  str.split(" ").map(word => capitalize(word)).join(" ");

export const convertCamelCaseToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const convertToRange = (value: string) =>
  value.replace(/[^\d^\-^+^,^\s]/ig, "");

export const MIMETYPE_ICON_MAP: Record<string, string> = {
  "application/vnd.google-apps.file": `${process.env.REACT_APP_STATIC_URL}/file-icon.svg`,
  "application/vnd.google-apps.folder": `${process.env.REACT_APP_STATIC_URL}/folder-icon.svg`,
  'application/vnd.google-apps.document': `${process.env.REACT_APP_STATIC_URL}/docs-icon.svg`,
  'application/vnd.google-apps.presentation': `${process.env.REACT_APP_STATIC_URL}/slides-icon.svg`,
  'application/vnd.google-apps.spreadsheet': `${process.env.REACT_APP_STATIC_URL}/sheets-icon.svg`,
  'application/vnd.google-apps.form': `${process.env.REACT_APP_STATIC_URL}/forms-icon.svg`,
  "application/vnd.google-apps.photo": `${process.env.REACT_APP_STATIC_URL}/image-icon.svg`,
  'application/pdf': `${process.env.REACT_APP_STATIC_URL}/pdf-icon.svg`,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": `${process.env.REACT_APP_STATIC_URL}/docx-icon.svg`,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": `${process.env.REACT_APP_STATIC_URL}/pptx-icon.svg`,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": `${process.env.REACT_APP_STATIC_URL}/xlsx-icon.svg`,
  'image/*': `${process.env.REACT_APP_STATIC_URL}/image-icon.svg`,
}

export const BLOCK_ICON_MAP: Record<string, string> = {
  GOOGLE_DOCS: `${process.env.REACT_APP_STATIC_URL}/docs-icon.svg`,
  GOOGLE_SLIDES: `${process.env.REACT_APP_STATIC_URL}/slides-icon.svg`,
  GOOGLE_SHEETS: `${process.env.REACT_APP_STATIC_URL}/sheets-icon.svg`,
  GOOGLE_FORMS: `${process.env.REACT_APP_STATIC_URL}/forms-icon.svg`,
  GOOGLE_DRIVE: `${process.env.REACT_APP_STATIC_URL}/drive-icon.svg`,
  GMAIL: `${process.env.REACT_APP_STATIC_URL}/gmail-icon.svg`,
  SIGNATURE_REQUEST: `${process.env.REACT_APP_STATIC_URL}/portant-sign-icon.svg`,
  WEBHOOK: `${process.env.REACT_APP_STATIC_URL}/webhook-icon.svg`,
  TALLY: `${process.env.REACT_APP_STATIC_URL}/tally-icon.png`,
  HUBSPOT: `${process.env.REACT_APP_STATIC_URL}/hubspot-icon.svg`,
  GLIDE: `${process.env.REACT_APP_STATIC_URL}/glide-icon.svg`,
  GLIDE_SHARE: `${process.env.REACT_APP_STATIC_URL}/glide-icon.svg`,
  PDF: `${process.env.REACT_APP_STATIC_URL}/pdf-icon.svg`,
  PDF_FILLABLE: `${process.env.REACT_APP_STATIC_URL}/pdf-icon.svg`,
  NONE: `${process.env.REACT_APP_STATIC_URL}/dummy.svg`,
  WRITE_TO_SOURCE: `${process.env.REACT_APP_STATIC_URL}/sheets-icon.svg`,
  XERO: `${process.env.REACT_APP_STATIC_URL}/xero-icon.svg`,
  REVIEW: `${process.env.REACT_APP_STATIC_URL}/review-icon.svg`,
  APPROVAL: `${process.env.REACT_APP_STATIC_URL}/dummy.svg`,
  APRIL_FOOLS: `${process.env.REACT_APP_STATIC_URL}/april-fools.png`,
  CHAT_GPT: `${process.env.REACT_APP_STATIC_URL}/openai-icon.png`,
  MICROSOFT: `${process.env.REACT_APP_STATIC_URL}/microsoft-icon.svg`,
  MICROSOFT_EXCEL: `${process.env.REACT_APP_STATIC_URL}/microsoft-excel-icon.svg`,
  OUTLOOK: `${process.env.REACT_APP_STATIC_URL}/microsoft-outlook-icon.svg`,
  MICROSOFT_WORD: `${process.env.REACT_APP_STATIC_URL}/docx-icon.svg`,
  MICROSOFT_POWERPOINT: `${process.env.REACT_APP_STATIC_URL}/pptx-icon.svg`,
  ZAPIER: `${process.env.REACT_APP_STATIC_URL}/zapier-icon.svg`,
  COPPER: `${process.env.REACT_APP_STATIC_URL}/copper-icon.png`,
  STRIPE: `${process.env.REACT_APP_STATIC_URL}/stripe-logo.png`,
  GOOGLE_SHEETS_MERGE: `${process.env.REACT_APP_STATIC_URL}/sheets-icon.svg`,
  GOOGLE_SHEETS_APPEND: `${process.env.REACT_APP_STATIC_URL}/sheets-icon.svg`,
};

export const BLOCK_NAME_MAP: Record<string, string> = {
  GOOGLE_DOCS: "Google Docs",
  GOOGLE_SLIDES: "Google Slides",
  GOOGLE_SHEETS: "Google Sheets",
  GOOGLE_FORMS: "Google Forms",
  GOOGLE_DRIVE: "Google Drive",
  GMAIL: "Gmail",
  WEBHOOK: "Webhook",
  TALLY: "Tally",
  GLIDE: "Glide",
  HUBSPOT: "HubSpot",
  WRITE_TO_SOURCE: "Write Links to Source",
  XERO: "Xero",
  REVIEW: "Review",
  APPROVAL: "Approval",
  MICROSOFT: "Microsoft",
  MICROSOFT_EXCEL: "Microsoft Excel",
  OUTLOOK: "Microsoft Outlook",
  ZAPIER: "Zapier",
  COPPER: "Copper",
  STRIPE: "Stripe",
  GOOGLE_SHEETS_MERGE: "Google Sheets",
  GOOGLE_SHEETS_APPEND: "Google Sheets",
};

export const GOOGLE_VIEW_ID_MAP: Record<string, string> = {
  GOOGLE_DOCS: 'DOCUMENTS',
  GOOGLE_SLIDES: 'PRESENTATIONS',
  GOOGLE_SHEETS: 'SPREADSHEETS',
  GOOGLE_FORMS: 'FORMS',
  GOOGLE_IMAGES: 'DOCS_IMAGES',
  PDF: "PDFS"
};

export const GOOGLE_MIMETYPE_MAP: Record<string, string> = {
  GOOGLE_DOCS: 'application/vnd.google-apps.document',
  GOOGLE_SLIDES: 'application/vnd.google-apps.presentation',
  GOOGLE_SHEETS: 'application/vnd.google-apps.spreadsheet',
  GOOGLE_FORMS: 'application/vnd.google-apps.form',
  GOOGLE_IMAGES: 'application/vnd.google-apps.photo',
  PDF: "application/pdf"
};

export const GOOGLE_TO_MICROSOFT_NAME_MAP: Record<string, string> = {
  GOOGLE_DOCS: 'Microsoft Word',
  GOOGLE_SLIDES: 'Microsoft PowerPoint',
  GOOGLE_SHEETS: 'Microsoft Excel',
  GOOGLE_SHEETS_MERGE: "Microsoft Excel",
  GOOGLE_SHEETS_APPEND: "Microsoft Excel",
};

export const GOOGLE_TO_MICROSOFT_MIMETYPE_MAP: Record<string, string> = {
  GOOGLE_DOCS: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  GOOGLE_SLIDES: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  GOOGLE_SHEETS: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  GOOGLE_SHEETS_MERGE: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  GOOGLE_SHEETS_APPEND: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const GOOGLE_TO_MICROSOFT_EXT_MAP: Record<string, string> = {
  GOOGLE_DOCS: 'DOCX',
  GOOGLE_SLIDES: 'PPTX',
  GOOGLE_SHEETS: 'XLSX',
  GOOGLE_SHEETS_MERGE: "XLSX",
  GOOGLE_SHEETS_APPEND: "XLSX",
};

export const SCOPE_MAP: Record<string, any> = {
  "https://www.googleapis.com/auth/drive.file": {
    title: "Google Drive",
    description: "Portant will only access files from your Google Drive that you select or files that are created for you by Portant.",
    icon: `${process.env.REACT_APP_STATIC_URL}/drive-icon.svg`,
    limitedScope: true,
  },
  "https://www.googleapis.com/auth/drive": {
    title: "Google Drive",
    description: "Portant will have access to files in your Google Drive. This is required to create new templates and outputs.",
    icon: `${process.env.REACT_APP_STATIC_URL}/drive-icon.svg`,
    fullScope: true,
  },
  "https://www.googleapis.com/auth/gmail.send": {
    title: "Gmail",
    description: "Portant will be able to send emails on your behalf from your Gmail account. The only emails that will be sent are based on your workflow settings within Portant.",
    icon: `${process.env.REACT_APP_STATIC_URL}/gmail-icon.svg`
  },
}

export const RECIPIENT_COLOUR_MAP: Record<number, Array<string>> = {
  1: ["text-blue", "bg-blue"],
  2: ["text-purple", "bg-purple"],
  3: ["text-green", "bg-green"],
  4: ["text-red", "bg-red"],
}

export const ORDINAL_MAP: Record<number, string> = {
  1: "1st",
  2: "2nd",
  3: "3rd",
  4: "4th",
}

export function getMimeTypeIcon(mimetype: string) {
  if (mimetype.startsWith("image/"))
    mimetype = "image/*";

  return MIMETYPE_ICON_MAP[mimetype] || MIMETYPE_ICON_MAP["application/vnd.google-apps.file"];
}

export const HUBSPOT_PRIMARY_OBJECT_OPTIONS = [
  { value: "DEAL", label: "Deal" },
  { value: "CONTACT", label: "Contact" },
  { value: "COMPANY", label: "Company" },
  { value: "MEETING", label: "Meeting" },
  { value: "TICKET", label: "Ticket" },
];

export const HUBSPOT_PRIMARY_OBJECT_ID_MAP = {
  "DEAL": "0-3",
  "CONTACT": "0-1",
  "COMPANY": "0-2",
  "TICKET": "0-5",
  "MEETING": "0-47",
}

export const HUBSPOT_OBJECT_TYPE_MAP = {
  "DEAL": "deals",
  "CONTACT": "contacts",
  "COMPANY": "companies",
  "TICKET": "tickets",
  "MEETING": "meetings",
}

export const CONVERSION_ROUTES = [
  "/google-forms-to-pdf",
  "/pdf-to-google-docs",
  "/hubspot-get-started",
  "/invoices",
];

export const SKIPPED_SECTIONS = ['/template']

// TODO: Move this to slugs so we are not hardcoding IDs
export const TEMPLATE_ROUTES_MAP = {
  "QUOTE": "tmp_ZpHlwKXVgWhCvm",
  "PROPOSAL": "tmp_ghMRT4zF7KdDL0",
  "CONTRACT": "tmp_dW15gRlPyK18dB",
  "INVOICE": "tmp_N9t6HXPGWpcwfL",
}

export function checkExpiry(token: string | null) {
  if (!token)
    return false;

  try {
    const payload: any = jwt_decode(token);
    const exp = new Date(payload.exp * 1000);

    return exp.getTime() < new Date().getTime();

  } catch {
    return false;
  }
}

export const DATE_FORMAT_MAP: Record<string, string> = {
  "DD MMMM YYYY": "DD Month YYYY",
  "MMMM DD, YYYY": "Month DD, YYYY",
  "MM/DD/YYYY": "MM/DD/YYYY",
  "DD/MM/YYYY": "DD/MM/YYYY",
  "YYYYMMDD": "YYYYMMDD",
  "DD.MM.YYYY": "DD.MM.YYYY",
}

export const TIME_FORMAT_MAP: Record<string, string> = {
  "hh:mmaa": "12 Hour",
  "HH:mm": "24 Hour",
  " ": "None",
}

export const NUMBER_FORMAT_MAP: Record<string, string> = {
  "COMMA": "Comma Separated (e.g. 1,234.00)",
  "PERIOD": "Period Separated (e.g. 1.234,00)",
}

export const DEFAULT_WORKFLOW_MAP: Record<string, any> = {
  "GOOGLE_SHEETS": { "color": "#54a26a", "icon": "Table" },
  "GOOGLE_FORMS": { "color": "#6c49b5", "icon": "ClipboardList" },
  "GOOGLE_DOCS": { "color": "#5e8bed", "icon": "DocumentText" },
  "GOOGLE_SLIDES": { "color": "#ebbd4d", "icon": "PresentationChartLine" },
}

export const ALLOWED_TAGS = ['p', 'a', 'b', 'u', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'span', 'div', 'table', 'tr', 'th', 'td'];

export const formatHTML = (code: string, stripWhiteSpaces: boolean, stripEmptyLines: boolean) => {
  const whitespace = ' '.repeat(4);             // Default indenting 4 whitespaces
  let currentIndent = 0;
  let char = null;
  let nextChar = null;
  let result = '';
  for (var pos = 0; pos <= code.length; pos++) {
    char = code.substr(pos, 1);
    nextChar = code.substr(pos + 1, 1);
    // If opening tag, add newline character and indention
    if (char === '<' && nextChar !== '/') {
      result += '\n' + whitespace.repeat(currentIndent);
      currentIndent++;
    }
    // if Closing tag, add newline and indention
    else if (char === '<' && nextChar === '/') {
      // If there're more closing tags than opening
      if (--currentIndent < 0) currentIndent = 0;
      result += '\n' + whitespace.repeat(currentIndent);
    }
    // remove multiple whitespaces
    else if (stripWhiteSpaces === true && char === ' ' && nextChar === ' ') char = '';
    // remove empty lines
    else if (stripEmptyLines === true && char === '\n') {
      //debugger;
      if (code.substr(pos, code.substr(pos).indexOf("<")).trim() === '') char = '';
    }
    result += char;
  }
  return result;
}

export function luminance(hexColour: string): number {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColour);
  if (!result)
    return 0;

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  // https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors

  const RED = 0.2126;
  const GREEN = 0.7152;
  const BLUE = 0.0722;

  const GAMMA = 2.4;

  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928
      ? v / 12.92
      : Math.pow((v + 0.055) / 1.055, GAMMA);
  });
  return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
}

export const TIER_URLS = {
  "bronze": "https://static.portant.co/medal-bronze.svg",
  "silver": "https://static.portant.co/medal-silver.svg",
  "gold": "https://static.portant.co/medal-gold.svg"
}

export type Tier = 'bronze' | 'silver' | 'gold';

export const COMPANY_ROLE_OPTIONS = [
  { value: 'CEO / Business Owner', label: 'CEO / Business Owner' },
  { value: 'Education / Training', label: 'Education / Training' },
  { value: 'IT / Engineering', label: 'IT / Engineering' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Project Management', label: 'Project Management' },
  { value: 'Product / Design', label: 'Product / Design' },
  { value: 'Customer Support', label: 'Customer Support' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Human Resources / People', label: 'Human Resources / People' },
  { value: 'Operations', label: 'Operations' },
  { value: 'Sales / Account Management', label: 'Sales / Account Management' },
  { value: '__OTHER', label: 'Other' }
];

export const COMPANY_INDUSTRY_OPTIONS = [
  { value: 'Accounting', label: 'Accounting' },
  { value: 'Administration & Office Support', label: 'Administration & Office Support' },
  { value: 'Advertising, Arts & Media', label: 'Advertising, Arts & Media' },
  { value: 'Banking & Financial Services', label: 'Banking & Financial Services' },
  { value: 'Call Centre & Customer Service', label: 'Call Centre & Customer Service' },
  { value: 'Community Services & Development', label: 'Community Services & Development' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Consulting & Strategy', label: 'Consulting & Strategy' },
  { value: 'Design & Architecture', label: 'Design & Architecture' },
  { value: 'Education & Training', label: 'Education & Training' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Farming, Animals & Conservation', label: 'Farming, Animals & Conservation' },
  { value: 'Government & Defence', label: 'Government & Defence' },
  { value: 'Healthcare & Medical', label: 'Healthcare & Medical' },
  { value: 'Hospitality & Tourism', label: 'Hospitality & Tourism' },
  { value: 'Human Resources & Recruitment', label: 'Human Resources & Recruitment' },
  { value: 'Information & Communication Technology', label: 'Information & Communication Technology' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Internet', label: 'Internet' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Manufacturing, Transport & Logistics', label: 'Manufacturing, Transport & Logistics' },
  { value: 'Marketing & Communications', label: 'Marketing & Communications' },
  { value: 'Mining, Resources & Energy', label: 'Mining, Resources & Energy' },
  { value: 'Real Estate & Property', label: 'Real Estate & Property' },
  { value: 'Retail & Consumer Products', label: 'Retail & Consumer Products' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Science & Technology', label: 'Science & Technology' },
  { value: 'Sport & Recreation', label: 'Sport & Recreation' },
  { value: 'Trades & Services', label: 'Trades & Services' },
  { value: '__OTHER', label: 'Other' }
]

export const COMPANY_SIZE_OPTIONS = [
  { value: 'N_1', label: "Just me" },
  { value: 'N_2_10', label: "2-10" },
  { value: 'N_11_20', label: "11-20" },
  { value: 'N_21_50', label: "21-50" },
  { value: 'N_51_100', label: "51-100" },
  { value: 'N_101_500', label: "101-500" },
  { value: 'N_501_PLUS', label: "501+" }
]

export const CURRENCY_SYMBOL: Record<string, string> = {
  "USD": "$",
  "AUD": "$",
  "CAD": "$",
  "EUR": "€",
  "GBP": "£",
}
